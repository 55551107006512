@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0;
  font-family: Inter, Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  max-width: 100vw;
}

#root {
  overflow-x: hidden !important;
}

body {
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#curved-border-top {
  bottom: 0;
  position: relative;
  width: 100vw;
  z-index: 999;
}

#curved-border-top path {
  fill: #f5fff7;
}

::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #87C996;
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  5. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  6. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  7. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  8. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}


/* dynamic styles */
p.regular {
  color: rgba(107, 114, 128, 1);
  font-family: "Inter-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 1em;
}

.redefine-link {
  color: #156763;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.redefine-ul {
  margin-top: 1em;
  list-style: none;
}

.redefine-ul li {
  color: #6b7280;
  font-family: "Inter-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  margin-left: -2em;
}

.bold {
  color: #6b7280;
  font-family: "Inter-SemiBold", Helvetica;
  font-weight: 600;
}

p.bold-italic {
  color: #6b7280;
  font-family: "Inter-Semibold Italic", Helvetica;
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
}

